/**
 * Create indexedDB database if not found
 *
 * @param {string} dbName
 * @returns {Promise}
 */
const createDatabase = (dbName) => {
	return new Promise((resolve, reject) => {
	  const request = indexedDB.open(dbName);

	  request.onupgradeneeded = function(event) {
		const db = event.target.result;
		const objectStore = db.createObjectStore("store", { keyPath: "key" });

		objectStore.createIndex("key", "key", { unique: true });
	  };
	  request.onsuccess = function(event) {
		const db = event.target.result;
		resolve(db);
	  };
	  request.onerror = function(event) {
		reject(event.target.error);
	  };
	});
  }

/**
 * Store time entries into indexedDB
 *
 * @param {string} dbName
 * @param {array} entries
 * @returns {Promise}
 */
const storeIntoDB = (dbName, entries) => {
	return new Promise((resolve, reject) => {
		const request = indexedDB.open(dbName);

		request.onsuccess = function(event) {
			const db = event.target.result;
			const transaction = db.transaction(["store"], "readwrite");
			const objectStore = transaction.objectStore("store");
			const object = { key: dbName, value: entries };

			objectStore.put(object);

			transaction.oncomplete = function() {
				console.log("Object stored successfully");
				resolve();
			};
			transaction.onerror = function(event) {
				reject(event.target.error);
			};
		};
		request.onerror = function(event) {
		reject(event.target.error);
		};
	});
}

/**
 * Retrieve time entries for indexedDB
 *
 * @param {string} dbName
 * @returns {Promise}
 */
const retrieveFromDB = (dbName) => {
	return new Promise((resolve, reject) => {
		const request = indexedDB.open(dbName);

		request.onsuccess = function(event) {
			const db = event.target.result;
			const transaction = db.transaction(["store"], "readonly");
			const objectStore = transaction.objectStore("store");
			const request = objectStore.get(dbName);

			request.onsuccess = function(event) {
				const object = event.target.result;
				if (object === undefined) {
					resolve([]);
				} else {
					resolve(object.value);
				}
			};
			request.onerror = function(event) {
				reject(event.target.error);
			};
		};
		request.onerror = function(event) {
			reject(event.target.error);
		};
	});
}

export { createDatabase, storeIntoDB, retrieveFromDB };
