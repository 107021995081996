import React, { useState, useRef, useEffect } from 'react';
import { Formik } from 'formik';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { FilterMatchMode } from 'primereact/api';
import { createDatabase, storeIntoDB, retrieveFromDB } from './helpers/database';
import { FileUpload } from 'primereact/fileupload';
import * as Yup from 'yup';

import { timeDiff, formatDate, dateToStr, uniqueColumnValues } from './helpers/common';
import { InputWrapper, AutoCompleteFilter } from './helpers/inputs';
import Clock from './helpers/Clock';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/md-light-indigo/theme.css';

const db = 'time-journal';
const tooltipOptions = { showDelay: 1000 };

/**
 * Displays the end time
 *
 * @param {object} rowData
 * @returns {ReactElement}
 */
const displayEndTime = (rowData) => {
	// If there's no specified end time, then display an active clock showing the current time
	if (rowData.endDate === '') {
			return <Clock />;
	}

	const splitted = formatDate(rowData.endDate).split(' ');

	// Format the end time as 12 hours. It's formally stored as military time for sorting
	return <span>{splitted[1]} {splitted[2]}</span>;
}

/**
 * Display duration in hours
 *
 * @component
 * @param {object} rowData
 * @returns {ReactElement}
 */
const displayHours = (rowData) => (
	<span>
		{rowData.duration} {rowData.duration && 'hrs'}
	</span>
);

/**
 * Show edit, pause, and delete buttons
 *
 * @component
 * @param {array}            data
 * @param {function(array)}  setData
 * @param {object}           rowData
 * @param {function(object)} setRow
 * @param {function(bool)}   setShowDialog
 * @returns {ReactElement}
 */
const ActionButtons = ({ data, setData, rowData, setRow, setShowDialog }) => (
	<div className="action-btn">
		<Button
			icon="pi pi-pencil"
			rounded text
			aria-label="Edit record"
			onClick={() => {
				setRow({
					...rowData,
					startTime: new Date(rowData.startDate),
					endTime: rowData.endDate === '' ? '' : new Date(rowData.endDate)
				});
				setShowDialog(true);
			}}
			tooltip="Edit"
			tooltipOptions={tooltipOptions}
		/>
		<Button
			icon={rowData.endDate  === '' ? "pi pi-stop" : "pi pi-clock"}
			rounded text
			aria-label="Update End Date"
			onClick={e => {
				const _data = data.map((x, i) => {
					if (x.id === rowData.id) {
						const endDate = dateToStr(new Date());
						const duration = timeDiff(rowData.startDate, endDate);
						return {
							...rowData,
							endDate: endDate,
							duration: duration,
						};
					} else {
						return x;
					}
				});

				setData(_data);
				storeIntoDB(db, _data);
			}}
			tooltip={rowData.endDate  === '' ? "Stop end date" : "Update end date"}
			tooltipOptions={tooltipOptions}
		/>
		<Button
			icon="pi pi-trash"
			rounded text
			aria-label="Delete record"
			severity="danger"
			onClick={e => {
				confirmDialog({
					trigger: e.currentTarget,
					message: 'Are you sure you want to proceed deletion?',
					header: 'Confirmation',
					icon: 'pi pi-exclamation-triangle',
					accept: () => {
						const _data = data.filter(x => x.id !== rowData.id);
						setData(_data);
						storeIntoDB(db, _data);
					},
				});
			}}
			tooltip="Delete"
			tooltipOptions={tooltipOptions}
		/>
	</div>
);

/**
 * Calculate the total hours
 */
const updateTotal = (data) => {
	const initialValue = 0;

	return data.map(x => Number(x.duration)).reduce(
		(accumulator, currentValue) => accumulator + currentValue,
		initialValue,
	)
}

const parseExcel = (file, setData) => {
	fetch(file).then(r => r.arrayBuffer()).then(buffer => {
		import('xlsx').then((xlsx) => {
			const workbook = xlsx.read(buffer, { type: 'blob' });
			const sheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[sheetName];
			const data = xlsx.utils.sheet_to_json(worksheet, { raw: false });

			storeIntoDB(db, data);
			setData(data);
		});
	});
};

/**
 * A time tracking application
 *
 * @component
 * @returns {ReactElement}
 */
const TimeJournal = () => {

	const [row, setRow] = useState({});
	const [data, setData] = useState([]);
	const [showDialog, setShowDialog] = useState(false);
	const [totalHours, setTotalHours] = useState()
	const [filters, setFilters] = useState({
		startDate: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
		endDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
		activity: { value: null, matchMode: FilterMatchMode.EQUALS },
		notes: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});

	const [loading, setLoading] = useState(true);
	const dt = useRef(null);

	const uniques = uniqueColumnValues(Object.keys(filters), data);

	// Retrieve a list of all the dates
	let dates = Array.from(new Set(data.map(x => x.startDate.split(' ')[0])));
	dates.sort().reverse();

	// Retrieve entries from indexedDB
	useEffect(() => {
		async function fetchData() {
			await createDatabase(db);
			const _data = await retrieveFromDB(db);
			setData(_data);
			setLoading(false);
		}
		fetchData();
	}, []);

	useEffect(() => {
		setTotalHours(updateTotal(data));
	}, [data]);

	/**
	 * Display edit, stop, and delete button for each row in the grid
	 * @param {*} rowData
	 * @returns
	 */
	const actionBtn = (rowData) => (
		<ActionButtons
			data={data}
			setData={setData}
			rowData={rowData}
			setRow={setRow}
			setShowDialog={setShowDialog}
		/>
	);

	const exportExcel = () => {
		import('xlsx').then((xlsx) => {
			const worksheet = xlsx.utils.json_to_sheet(data);
			const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
			const excelBuffer = xlsx.write(workbook, {
				bookType: 'xlsx',
				type: 'array'
			});

			saveAsExcelFile(excelBuffer, 'time-tracker');
		});
	};

	const saveAsExcelFile = (buffer, fileName) => {
		import('file-saver').then((module) => {
			if (module && module.default) {
				let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
				const data = new Blob([buffer], {
					type: EXCEL_TYPE
				});

				module.default.saveAs(data, `${fileName}_${new Date().getTime()}.xlsx`);
			}
		});
	};

	/**
	 * A dropdown list of dates as a filter
	 *
	 * @param {object} options
	 * @returns {ReactElement}
	 */
	const dateFilterTemplate = (options) => (
		<Dropdown
			value={options.value}
			options={dates}
			onChange={(e) => options.filterApplyCallback(e.value)}
			placeholder="Filter Date"
			className="p-column-filter"
			showClear
		/>
	);

	/**
	 * A dropdown list of activities as a filter
	 *
	 * @param {object} options
	 * @returns {ReactElement}
	 */
	const filterTemplate = (options, column) => (
		<Dropdown
			value={options.value}
			options={uniques[column]}
			onChange={(e) => options.filterApplyCallback(e.value)}
			placeholder={"Filter "+column}
			className="p-column-filter"
			showClear
		/>
	);

	/**
	 * Display action buttons above the grid
	 *
	 * @returns {ReactElement}
	 */
	const header = () => (
		<div id="toolbar" className="flex justify-content-end">
			<div className="toolbar-left">
				<Button
					aria-label="Add Entry"
					icon="pi pi-plus" rounded
					onClick={(e) => {
						setShowDialog(true);
						setRow({
							startTime: new Date(),
							endTime: '',
							activity: '',
							notes: '',
						});
					}}
					tooltip="Add"
					tooltipOptions={tooltipOptions}
				/>

			</div>
			<div className="toolbar-right">
				<FileUpload
					mode="basic"
					name="excel"
					icon="pi pi-file-excel" rounded
					url="upload.php"
					accept="xlsx/*"
					maxFileSize={1000000}
					tooltip="Import excel"
					uploadLabel="hello"
					uploadOptions={{ iconOnly: true }}
					onUpload={(e) => {
						parseExcel(e.xhr.response, setData);
					}}
				/>
				<Button
					type="button"
					label="Export"
					aria-label="Export"
					// icon="pi pi-file-excel" rounded
					style={{ height: '42px' }}
					onClick={exportExcel}
					tooltip="Export to excel"
					tooltipOptions={{ position: 'left', showDelay: 1000 }}
				/>
			</div>
		</div>
	);

	return (
		<div id="time-clock">
			<ConfirmDialog />
			<DataTable
				ref={dt}
				value={data}
				paginator rows={25} rowsPerPageOptions={[25, 100, 500]}
				dataKey="id"
				stripedRows
				footer={totalHours !== undefined ? `Total: ${totalHours.toFixed(1)} hrs` : ''}
				header={header}
				filters={filters} filterDisplay="row"
				onValueChange={filteredData => setTotalHours(updateTotal(filteredData))}
			>
				<Column
					body={actionBtn}
					className="action-column"
				/>
				<Column
					field="startDate"
					header="Start Date"
					body={rowData => formatDate(rowData.startDate)}
					className="time-column"
					filter filterField="startDate" showFilterMenu={false} filterPlaceholder="Filter " filterElement={dateFilterTemplate}
					sortable
				/>
				<Column
					field="endDate"
					header="End Date"
					body={displayEndTime}
					className="time-column"
					filter filterField="endDate" showFilterMenu={false} filterPlaceholder="Filter "
					sortable
				/>
				<Column
					field="activity"
					header="Activity"
					className="activity-column"
					filter filterField="activity" showFilterMenu={false}
					filterElement={options => filterTemplate(options, 'activity')}
					sortable
				/>
				<Column
					field="notes"
					header="Notes"
					className="notes"
					filter filterField="notes" showFilterMenu={false} filterPlaceholder="Filter notes"
					sortable
				/>
				<Column
					field="duration"
					header="Duration"
					body={displayHours}
					className="time-column"
					filter filterField="duration" showFilterMenu={false} filterPlaceholder="Filter "
					sortable
				/>
			</DataTable>
			<Dialog header="Add Event" visible={showDialog} style={{ width: '35rem' }} onHide={() => setShowDialog(false)}>
				<Formik
					initialValues={row}
					validationSchema={Yup.object().shape({
						startTime: Yup.string().required('Required')
					})}
					onSubmit={values => {

						values.startDate = dateToStr(values.startTime);
						values.endDate = values.endTime !== '' ? dateToStr(values.endTime) : '';
						values.duration = timeDiff(values.startDate, values.endDate);

						let _data = [];

						// If an id exists, then update data rather than adding data
						if (values.id !== undefined) {
							_data = data.map((x, i) => (x.id === values.id) ? values : x);

							// Create an id
						} else {
							if (data.length === 0) {
								values.id = 0;
							} else {
								values.id = Math.max(...data.map(x => x.id))+1;
							}

							_data = [values, ...data];
						}

						setData(_data);
						storeIntoDB(db, _data);
						setShowDialog(false);
					}}
					>
					{props => (
						<form onSubmit={props.handleSubmit}>
							<InputWrapper name="startTime" formikProps={props} title="Start Time">
								<Calendar
									name="startTime"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.startTime}
									showButtonBar showTime hourFormat="12"
								/>
							</InputWrapper>
							<InputWrapper name="endTime" formikProps={props} title="End Time">
								<Calendar
									name="endTime"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.endTime}
									showButtonBar showTime hourFormat="12"
								/>
							</InputWrapper>
							<InputWrapper name="activity" formikProps={props} title="Activity">
								<AutoCompleteFilter
									name="activity"
									list={uniques}
									formikProps={props}
								/>
							</InputWrapper>
							<InputWrapper name="notes" formikProps={props} title="Notes">
								<InputTextarea
									name="notes"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.notes}
									rows={5} cols={30}
								/>
							</InputWrapper>
							<Button label="Save" type="submit" disabled={props.isSubmitting} />
						</form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
}

export default TimeJournal;
