import './App.css';
import React from 'react';
import TimeJournal from './TimeJournal';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/md-light-indigo/theme.css';

const App = () => (
	<TimeJournal />
)

export default App;
