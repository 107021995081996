import React, { useState, useEffect } from 'react';

const Clock = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
	const interval = setInterval(() => {
	  setTime(new Date());
	}, 1000);

	return () => clearInterval(interval);
  }, []);

  const hours = time.getHours() % 12 || 12;
  const minutes = time.getMinutes();
  const seconds = time.getSeconds();
  const amPm = time.getHours() < 12 ? 'AM' : 'PM';

  return (
	<div className="clock-ticking">
	  {hours}:{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds} {amPm}
	</div>
  );
}

export default Clock;
