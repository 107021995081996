/**
 * Format date to yyyy-mm-dd hh:mm:ss format
 *
 @param {object} date
 @returns {string}
 */
 const dateToStr = (date) => {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	const seconds = String(date.getSeconds()).padStart(2, '0');

	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

/**
 * Convert ot 12hr time
 *
 * @param {string} militaryTime
 * @returns {string}
 */
const convertTo12HourTime = (militaryTime) => {
	if (militaryTime === '' || militaryTime === undefined) {
		return '';
	}
	// Split the input string into hours, minutes, and seconds
	const [hours, minutes] = militaryTime.split(':');

	// Convert the hours to 12-hour format
	let hours12 = parseInt(hours, 10) % 12;
	hours12 = hours12 === 0 ? 12 : hours12;

	// Determine whether it's AM or PM
	const amPm = parseInt(hours, 10) < 12 ? 'AM' : 'PM';

	// Return the formatted time string
	return `${hours12}:${minutes} ${amPm}`;
}

const formatDate = (dateStr) => {
	const [date, time] = dateStr.split(' ');

	return `${date} ${convertTo12HourTime(time)}`;
}

/**
 * Find the difference between two date strings
 *
 * @param {string} date1 ISO date string
 * @param {string} date2 ISO date string
 * @returns {string}
 */
const timeDiff = (date1, date2) => {
	if (date2 === '') {
		return '';
	}

	const diffInMs = Math.abs(new Date(date2) - new Date(date1));
	const diffInHours = diffInMs / (1000 * 60 * 60);

	return `${diffInHours.toFixed(1)}`;
}

/**
 * Find unique values for each columns
 *
 * @param {array}  columns
 * @param {array}  data
 * @returns {object}
 */
const uniqueColumnValues = (columns, data) => {
	const uniques = {};

	if (data.length > 0) {
		// Populate unique values from each columns
		for (let column of columns) {
			uniques[column] = Array.from(new Set(data.map(x => x[column]))).filter(x => x !== undefined);

			if (column === 'date') {
				uniques[column].reverse();
			} else {
				uniques[column].sort();
			}
		}
	} else {
		for (let column of columns) {
			uniques[column] = [];
		}
	}

	return uniques;
}

export { convertTo12HourTime, timeDiff, formatDate, dateToStr, uniqueColumnValues };
