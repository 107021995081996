import React, { useState } from 'react';
import { AutoComplete } from 'primereact/autocomplete';

/**
 * Wrap input form elements
 *
 * @component
 * @param {*} param0
 * @returns
 */
const InputWrapper = ({ formikProps, children, name, title }) => (
	<div className="input-wrapper">
		<label>{title}<br/>
		{children}
		</label>
		<div className="error-message">
		{formikProps.errors[name] && formikProps.touched[name] && formikProps.errors[name]}
		</div>
	</div>
)

const AutoCompleteFilter = ({ formikProps, name, list }) => {
	const [autoComplete, setAutoComplete] = useState(list[name]);

	/**
	 * Search for a matching query
	 */
	const search = (event) => {
		if (list[name] === undefined) {
			setAutoComplete([]);
		} else {
			setAutoComplete([...list[name].filter(x => x.toLowerCase().includes(event.query.toLowerCase()))]);
		}
	}

	return (
		<AutoComplete
			name={name}
			onChange={formikProps.handleChange}
			onBlur={formikProps.handleBlur}
			value={formikProps.values[name]}
			suggestions={autoComplete}
			completeMethod={search}
		/>
	);
};

export { InputWrapper, AutoCompleteFilter };
